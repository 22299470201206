<template>
  <div>
    <div class="category-box winAndplayCategory groupChatbox">
      <div class="crousel_outer">
        <div class="catgory_head">
          <div class="title">
            <h4>
              <img src="/static/images/group_ic.svg" class="titile_icon messageAnimate" />{{$t('joinGrpChat')}}
            </h4>
          </div>
          <!-- <div class="categorytab">
            <img src="/static/images/right_arrow_ic.svg" />
          </div> -->
        </div>
        <section class="multiple_user">
          <div class="user_container">
            <ul class="group_chat_cards">

              <li v-if="index%2===0" v-for="(chatDetails,index) in groupDetails" :key="index">
<!--         Showing atmost 2 items in a column at every iteration and checking even indexes only      -->
                <div  v-if="index%2===0" @click="gotoRoom(chatDetails._id)" class="chat_card">
                  <img
                    :src="chatDetails.profileImageUrl"
                    v-if="chatDetails.profileImageUrl!==''"
                    class="profile_img"
                  />
                  <img src="../assets/img/user_images.png" class="profile_img" v-else />
                  <div class="card_details">
                    <h4>{{chatDetails.roomName}}</h4>
                    <div class="counter">
                      <span>
                        <i class="material-icons">person</i>
                        {{chatDetails.members}}
                      </span>
                    </div>
                  </div>
                </div>
                <div v-if="(index+1)%2===1 && index < groupDetailsLength"
                     @click="gotoRoom(groupDetails[index+1]._id)" class="chat_card">
                  <img
                    :src="groupDetails[index+1].profileImageUrl"
                    v-if="groupDetails[index+1].profileImageUrl!==''"
                    class="profile_img"
                  />
                  <img src="../assets/img/user_images.png" class="profile_img" v-else />
                  <div class="card_details">
                    <h4>{{groupDetails[index+1].roomName}}</h4>
                    <div class="counter">
                      <span>
                        <i class="material-icons">person</i>
                        {{groupDetails[index+1].members}}
                      </span>
                    </div>
                  </div>
                </div>

              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
export default {
  name: "JoinGroupChatCrousel",
  props: {},
  data() {
    return {
      spinnerLoading: false,
      groupDetails: [],
      caraousalarray: [],
      groupDetailsLength:0
    };
  },


  methods: {


    chatGroupDetails() {
          let _this = this;
      this.$http
        .post(`${_this.$store.state.serverUrl}api/chatrooms`, {
          isDefault: true,
        })
        .then(
          function(data) {
            this.groupDetails = data.body.rooms;
            this.groupDetailsLength = this.groupDetails.length;
            // for (let i = 0, j = 0; i < this.groupDetails.length; i++) {
            //   let arr = [];
            //   arr[0] = this.groupDetails[i];
            //   if (this.groupDetails[i + 1]) {
            //     arr[1] = this.groupDetails[i + 1];
            //     i++;
            //   }
            //   this.caraousalarray[j] = arr;
            //   j++;
            // }
          },
          response => {
            // console.log("something went wrong");
          }
        );
    },

    gotoRoom(url) {
        this.$router.push(`new-chat-room/${url}`);
      // this.xauthToken = this.$cookie.get("X-Auth-Token");
      // console.log(`${url}?xauthToken=${this.xauthToken}`);
      // window.location.href = url+'?xauthToken='+this.xauthToken

    },

  },
  mounted() {
    this.chatGroupDetails();
  }
};
</script>
<style>
</style>
